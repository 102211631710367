import {HttpClient, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class OauthTokenService {
	webUrl : string;
	token: string;
	httpOptions;

	constructor(private http: HttpClient) {}

	fetchRole(userId){
		this.token = 'Bearer '+sessionStorage['gateway_access_token'];
		this.httpOptions = {
			headers: new HttpHeaders({ 'Content-Type': 'application/json' ,'Authorization':this.token}),
		};
		this.webUrl = environment.orderUrl + 'api/v1/mdpdecision/';
		return this.http.get(this.webUrl+userId, this.httpOptions);
	}
}
